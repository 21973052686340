import React, { ReactNode } from "react";
import styles from "./Aside.module.scss";
import classNames from "classnames";

interface AsideProps {
  className?: string;
  children: ReactNode;
  sticky?: boolean;
}

const Aside = ({ className, children, sticky = true }: AsideProps) => (
  <aside
    className={classNames(className, styles.root, { [styles.sticky]: sticky })}
  >
    {children}
  </aside>
);

export default Aside;
