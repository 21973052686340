import React, { useState, useEffect } from "react";
import Campaign from "../../components/Campaign/Campaign";
import { SanityCampaign } from "../../model/common";

interface CampaignRandomProps {
  className?: string;
  campaigns: SanityCampaign[];
  orientation: "horizontal" | "vertical";
}

const CampaignsRandom = ({
  className,
  campaigns,
  orientation
}: CampaignRandomProps) => {
  // Must choose client side so rehydration matches.
  const [chosenCampaign, setChosenCampaign] = useState<
    SanityCampaign | undefined
  >(undefined);
  useEffect(() => {
    // This can be removed when all campaigns have been migrated to the new format.
    const valid = campaigns.filter(
      c => c.mainImageVertical && c.mainImageHorizontal
    );
    if (valid.length > 0) {
      setChosenCampaign(valid[Math.floor(Math.random() * valid.length)]);
    }
  }, [campaigns]);

  if (!chosenCampaign) {
    return null;
  }
  return (
    <Campaign
      orientation={orientation}
      className={className}
      campaign={chosenCampaign}
    />
  );
};

export default CampaignsRandom;
