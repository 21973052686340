import React from "react";
import styles from "./Campaign.module.scss";
import BlockContent from "../BlockContent/BlockContent";
import classnames from "classnames";
import { SanityCampaign } from "../../model/common";
import { imageUrlBuilder } from "../../utils/images";
import CallToActionButton from "../CallToActionButton/CallToActionButton";
import { graphql } from "gatsby";
import { internalLinkUrl } from "../InternalLink/InternalLink";
import Link from "../Link/Link";
import { useLanguage } from "../../i18n/LanguageContext";
import Card from "../Card/Card";

export const campaignFragment = graphql`
  fragment Campaign on SanityCampaign {
    _id
    backgroundColor
    _rawContent(resolveReferences: { maxDepth: 2 })
    callToAction {
      title
      internalLink {
        reference {
          ...InternalLinkTarget
        }
        query
        fragment
      }
      externalLink {
        href
      }
    }
    mainImageVertical {
      ...SanityMinimalImage
    }
    mainImageHorizontal {
      ...SanityMinimalImage
    }
  }
`;

interface CampaignProps {
  campaign: SanityCampaign;
  className?: string;
  orientation: "horizontal" | "vertical";
}

const Campaign = ({ className, campaign, orientation }: CampaignProps) => {
  const { backgroundColor } = campaign;
  const content = (campaign._rawContent || campaign.content)!;
  const externalLink = campaign.callToAction?.externalLink?.href;
  const internalLink = campaign.callToAction?.internalLink?.reference;
  const query = campaign.callToAction?.internalLink?.query;
  const fragment = campaign.callToAction?.internalLink?.fragment;
  const language = useLanguage();
  const to = internalLink
    ? internalLinkUrl(internalLink, query, fragment, language)
    : externalLink || "";

  return (
    <Card
      className={classnames(
        styles[orientation],
        styles.root,
        styles[backgroundColor],
        className,
        orientation === "horizontal" && styles.sideBySide
      )}
      dir="ltr"
    >
      <BlockContent
        className={styles.campaignContent}
        bodyVariant="footerHeading"
        // We've hit some issues with the carousel campaign images not displaying in Chrome.
        imageLoading="auto"
        content={content}
        after={[
          orientation === "vertical" && (
            <Link key="img" to={to} className={styles.mainImageLink}>
              <img
                width="210"
                height="263"
                alt={campaign.mainImageVertical.alt}
                src={
                  imageUrlBuilder
                    .image(campaign.mainImageVertical)
                    .width(210)
                    .height(263)
                    .url()!
                }
              />
            </Link>
          ),
          <CallToActionButton
            key="cta"
            node={{
              primary: true,
              title: campaign.callToAction?.title,
              externalLink,
              internalLink,
              query,
              fragment
            }}
          />
        ]}
      />

      {orientation === "horizontal" && (
        <div className={styles.sideBySideImageWrapper}>
          <Link to={to} className={styles.mainImageLink}>
            <img
              width="510"
              height="340"
              alt={campaign.mainImageHorizontal.alt}
              src={
                imageUrlBuilder
                  .image(campaign.mainImageHorizontal)
                  .width(510)
                  .height(340)
                  .url()!
              }
            />
          </Link>
        </div>
      )}
    </Card>
  );
};

export default Campaign;
